import Vue from "vue";
import axios from "axios";

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Accept: "application/json",
    },
    withCredentials: false,
    // timeout: 60000
});

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

http.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem("token");

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error.response);
    }
);

http.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (!error.response) {
            Vue.swal({
                type: "error",
                title: "Network error",
                text:
                    "ไม่สามารถติดต่อเซอเวอร์ได้ กรุณาลองใหม่ภายหลังหรือ ติดต่อเจ้าหน้าที่ผู้ดูแล",
            });
            return;
        }
        let {
            config,
            response: { status },
        } = error;
        let originalRequest = config;

        if (status === 401) {
            let token = localStorage.getItem("token");
            let refreshToken = localStorage.getItem("refresh_token");

            if (!refreshToken && !token) return Promise.reject(error.response);

            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;

                axios
                    .post(process.env.VUE_APP_BASE_API + "auth/token", {
                        refreshToken: refreshToken,
                    })
                    .then((resp) => {
                        isAlreadyFetchingAccessToken = false;
                        localStorage.setItem("token", resp.data.accessToken);
                        originalRequest.headers.Authorization =
                            "Bearer " + resp.data.accessToken;
                        onAccessTokenFetched(resp.data.accessToken);
                    })
                    .catch(() => {
                        window.location.href = process.env.VUE_APP_URL +"login";
                    });
            }

            const retryOriginalRequest = new Promise((resolve) => {
                addSubscriber((access_token) => {
                    originalRequest.headers.Authorization = "Bearer " + access_token;
                    resolve(axios.request(originalRequest));
                });
            });
            return retryOriginalRequest;
        }

        return Promise.reject(error.response);
    }
);
export default http;